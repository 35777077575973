<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <b-col class="mt-2">
            <b-card-text>
              Listed all commodities without HS codes. Please fill in HS code values yourself or use our suggestions.<br> Only you are responsible for filling in correct HS codes.
            </b-card-text>
          </b-col>
          <b-col
            v-if="canEdit"
            class="text-right"
          >
            <b-button
              variant="outline-warning"
              class="m-1"
              @click="applyFirstSuggestionForAll"
            >
              Apply first suggestion to all commodities
            </b-button>
            <b-button
              variant="outline-success"
              class="m-1"
              @click="saveCommodities"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-observer ref="hsCodesValidation">
              <b-table
                :items="commodities"
                :fields="fields"
                responsive
                show-empty
                empty-text="No matching records found"
                class="mb-0"
              >

                <template #cell(description)="data">
                  {{ data.item.description }}
                </template>

                <template #cell(count)="data">
                  {{ data.item.total }}
                </template>

                <template #cell(hs_codes)="data">
                  <validation-provider
                    #default="{ errors }"
                    :name="data.item.hs_code"
                    rules="required|min:6|max:10"
                  >
                    <b-form-input
                      v-model="data.item.hs_code"
                      placeholder="HS code"
                      autocomplete="off"
                      :state="errors.length > 0 ? false:null"
                    />
                  </validation-provider>
                </template>

                <template #cell(suggestions)="data">
                  <b-button
                    v-for="(suggestion, index) in data.item.suggestions"
                    :key="index"
                    type="button"
                    :variant="data.item.hs_code === suggestion ? 'success' : 'outline-success'"
                    class="mr-1"
                    @click="data.item.hs_code = suggestion"
                  >
                    {{ suggestion }}
                  </b-button>
                </template>

              </b-table>
            </validation-observer>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BTable,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BTable,
  },
  data() {
    return {
      commodities: [],
      fields: [
        { key: 'description', label: 'Description' },
        { key: 'total', label: 'total' },
        { key: 'hs_codes', label: 'HS codes' },
        { key: 'suggestions', label: 'Suggested HS codes' },
      ],
    }
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
  },
  created() {
    this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.$attrs.shipmentId}/missing-hs-codes`)
      .then(response => {
        this.commodities = response.data.data
      })
  },
  methods: {
    applyFirstSuggestionForAll() {
      this.commodities.forEach(commodity => {
        if (commodity.suggestions.length > 0) {
          [commodity.hs_code] = commodity.suggestions
        }
      })
    },
    saveCommodities() {
      this.$refs.hsCodesValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.$http.post(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.$attrs.shipmentId}/missing-hs-codes`,
          {
            commodities: this.commodities,
          })
          .then(response => {
            this.commodities = response.data.data
          })
      })
    },
  },
}
</script>
